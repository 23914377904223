<template>
<div>
  <Header></Header>
  <!--顶部banner开始-->
  <div class="aboutus-banner left relative" style="margin-bottom:50px;"> <img src="~images/banner/yewulingyu.jpg" width="100%" /> </div>
  <div id="i-about-box"> 
    
  <!--领域图片开始-->
  <div id="b-image">
    <router-link :to="{path:'/business_detail',query:{id:item.id}}" v-bind:title="item.title" v-for="item in data" v-bind:key="item.id">
        <div class="b-image_box left">
            <div class="b-images left"> <img v-bind:src="item.img_path" width="400" height="302" /> </div>
            <p class="b-images-title">{{item.title}}</p>
            <p class="b-images-ftitle">{{item.ftitle}}</p>
        </div>
    </router-link>
  </div>

  </div>
   
  <div class="left clearfix width-bai"> <Footer></Footer> </div>

</div>
</template>

<script>
import Header from '../layout/Header.vue'
import Footer from '../layout/Footer.vue'

export default { 
name:"",

data() {
      return {
          data:''
      }
},

mounted() {
  this.$axios.post(window.configs.svcProt + "://" + window.configs.svcIpPort + '/get_business.html?token='+this.$store.state.token)
  .then(res=>{
    this.data=res.data.result;
  });
},

components:{
    Header,
    Footer
}

}
</script>

<style scoped>
    .b-images-title{line-height:45px;font-size:18px;font-weight:bold;padding-left:10px;}
    .b-images-ftitle{line-height:25px;font-size:15px;color:#999;padding-left:10px;}
    .b-images{width:400px;height:300px;text-align:center;overflow:hidden;}
    .b-images img{transition: all 2s;cursor:pointer;}
    .b-images img:hover{transform: scale(1.4);}
    .b-image_box{width:400px;height:390px;border-bottom:3px solid rgb(242,151,0);margin:20px 25px 20px 45px;box-shadow: 0px 2px 5px #cccccc;}
    #b-image{width:100%;height:880px;}
    #i-about-box{width:1440px;height:1000px;margin:auto;}
</style>
